import '@com/popup/style.scss';
import * as $ from 'jquery';
// import { Fancybox } from '@fancyapps/fancybox';
import '@fancyapps/fancybox';

// Fancybox.bind('.fancybox', {
//   // Your options go here
// });

$('.fancybox').fancybox();
